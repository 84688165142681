<template>
  <b-nav-item id="transaction-add" @click="openModal">
    <feather-icon icon="FilePlusIcon" size="21" />
    <b-tooltip
      triggers="hover"
      target="transaction-add"
      title="Добавить сделку"
      :delay="{ show: 500, hide: 50 }"
    />
  </b-nav-item>
</template>

<script>
export default {
  name: 'TransactionAddBtn',
  methods: {
    openModal() {
      this.$store.commit('investments/SET_TRANSACTION', undefined);
      this.$bvModal.show('modal-transaction');
    },
  },
};
</script>

<style></style>
