<template>
  <b-nav-item id="currency-change" @click="changeCurrency">
    <h4 class="mb-0">{{ $store.getters["investments/curCurrency"].symbol }}</h4>
    <b-tooltip
      triggers="hover"
      target="currency-change"
      title="Изменить валюту"
      :delay="{ show: 500, hide: 50 }"
    />
  </b-nav-item>
</template>

<script>
export default {
  name: "CurrencyButton",
  methods: {
    changeCurrency() {
      this.$store.dispatch("investments/changeCurrency");
    },
  },
};
</script>

<style></style>
