<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <b-navbar-nav class="nav align-items-center mr-auto">
      <portfolio-drop-down />
      <currency-button v-if="!!$store.getters['investments/curPortfolio'].name" />
      <transaction-add-btn v-if="!!$store.getters['investments/curPortfolio'].name" />
    </b-navbar-nav>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
import UserDropdown from './components/UserDropdown.vue';
import PortfolioDropDown from './components/PortfolioDropDown.vue';
import TransactionAddBtn from './components/TransactionAddBtn.vue';
import CurrencyButton from './components/CurrencyButton.vue';

export default {
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    DarkToggler,
    UserDropdown,
    PortfolioDropDown,
    TransactionAddBtn,
    CurrencyButton,
  },
};
</script>

<style></style>
