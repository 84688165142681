<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.fullName }}
        </p>
      </div>
      <b-avatar size="40" :src="userData.avatar" variant="light-primary">
        <feather-icon v-if="!userData.fullName" icon="UserIcon" size="22" />
      </b-avatar>
    </template>
    <template v-if="$can('update', 'Price')">
      <b-dropdown-item link-class="d-flex align-items-center" @click="updatePrices">
        <feather-icon size="16" icon="DownloadCloudIcon" class="mr-50" />
        <span>Загрузка цен</span>
      </b-dropdown-item>

      <b-dropdown-item link-class="d-flex align-items-center" @click="updateHistoricalPrices">
        <span>Загрузка ист. цен</span>
      </b-dropdown-item>

      <b-dropdown-item link-class="d-flex align-items-center" @click="updateRates">
        <span>Загрузка курса</span>
      </b-dropdown-item>

      <b-dropdown-item link-class="d-flex align-items-center" @click="updateSecData">
        <span>Об. данных по ЦБ</span>
      </b-dropdown-item>

      <!-- <b-dropdown-item link-class="d-flex align-items-center" @click="gotoAdmin">
        <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
        <span>Админ. панель</span>
      </b-dropdown-item> -->

      <b-dropdown-divider />
    </template>
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Выход</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { avatarText } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  updateDailyPrices,
  updateHistoricalPrices,
  updateSecuritiesData,
  updateExchangeRates,
} from "@/api/investments";

export default {
  data() {
    return {
      avatarText,
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("user/logout");
      const rules = this.$store.getters["ability/getRules"];
      this.$ability.update(rules);
    },
    async updatePrices() {
      try {
        await updateDailyPrices();
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Выполнено",
            icon: "ThumbsUpIcon",
            variant: "success",
            text: "Загрузка цен выполнена",
          },
        });
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Ошибка",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: "Ошибка обновления цен",
          },
        });
      }
    },
    async updateHistoricalPrices() {
      try {
        await updateHistoricalPrices();
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Выполнено",
            icon: "ThumbsUpIcon",
            variant: "success",
            text: "Загрузка цен выполнена",
          },
        });
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Ошибка",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: "Ошибка обновления цен",
          },
        });
      }
    },
    async updateSecData() {
      try {
        await updateSecuritiesData();
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Выполнено",
            icon: "ThumbsUpIcon",
            variant: "success",
            text: "Загрузка данных выполнена",
          },
        });
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Ошибка",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: "Ошибка загрузки данных",
          },
        });
      }
    },
    async updateRates() {
      try {
        await updateExchangeRates();
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Выполнено",
            icon: "ThumbsUpIcon",
            variant: "success",
            text: "Загрузка курсов валют выполнена",
          },
        });
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Ошибка",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: "Ошибка обновления курсов валют",
          },
        });
      }
    },
    gotoAdmin() {
      window.open("/api/admin/");
    },
  },
};
</script>
