<template>
  <b-nav-item-dropdown left toggle-class="d-flex align-items-center dropdown-user-link">
    <template #button-content>
      <feather-icon icon="BriefcaseIcon" size="22" id="portfolio-icon" />
      <span class="ml-50 text-body d-sm-flex d-none">{{ currentPortfolio.name }}</span>
      <b-tooltip
        triggers="hover"
        target="portfolio-icon"
        title="Выбор портфеля"
        :delay="{ show: 500, hide: 50 }"
      />
    </template>
    <b-dropdown-item
      v-for="portfolio in activePortfolios"
      :key="portfolio.id"
      @click="changePortfolio(portfolio.id)"
    >
      <span class="ml-50">{{ portfolio.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { onMounted } from "@vue/composition-api";
import store from "@/store";
import usePortfolio from "@/comp-functions/usePortfolio";

export default {
  name: "PortfolioDropDown",
  setup() {
    const { activePortfolios, currentPortfolio, changePortfolio } = usePortfolio();
    onMounted(async () => {
      await store.dispatch("investments/fetchPortfolios");
    });

    return {
      activePortfolios,
      currentPortfolio,
      changePortfolio,
    };
  },
};
</script>

<style></style>
